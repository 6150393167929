class SegmentClient {
  // provide a default id so that segment can be used when embedded in another page
  static DEFAULT_SEGMENT_WEBSITE_ID = "MW2Y3qAAZ5FAYp0zXznmjcsKDL1O9hvO";

  static init() {
    const segmentID =
      window.SEGMENT_WEBSITE_ID || this.DEFAULT_SEGMENT_WEBSITE_ID;

    // loading segmetn as per this snippet
    // https://segment.com/docs/sources/website/analytics.js/quickstart/#step-1-copy-the-snippet
    /* eslint-disable */

    (function() {
      // Create a queue, but don't obliterate an existing one!
      const analytics = (window.analytics = window.analytics || []);

      // If the real analytics.js is already on the page return.
      if (analytics.initialize) return;

      // If the snippet was invoked already show an error.
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error("Segment snippet included twice.");
        }
        return;
      }

      // Invoked flag, to make sure the snippet
      // is never invoked twice.
      analytics.invoked = true;

      // A list of the methods in Analytics.js to stub.
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on"
      ];

      // Define a factory to create stubs. These are placeholders
      // for methods in Analytics.js so that you never have to wait
      // for it to load to actually record data. The `method` is
      // stored as the first argument, so we can replay the data.
      analytics.factory = function(method) {
        return function() {
          const args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };

      // For each of our methods, generate a queueing stub.
      for (let i = 0; i < analytics.methods.length; i++) {
        const key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }

      // Define a method to load Analytics.js from our CDN,
      // and that will be sure to only ever load it once.
      analytics.load = function(key, options) {
        // Create an async script element based on your key.
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

        // Insert our script next to the first script element.
        const first = document.getElementsByTagName("script")[0];
        first.parentNode.insertBefore(script, first);
        analytics._loadOptions = options;
      };

      // Add a version to keep track of what's in the wild.
      analytics.SNIPPET_VERSION = "4.1.0";

      // Load Analytics.js with your key, which will automatically
      // load the tools you've enabled for your account. Boosh!
      analytics.load(segmentID);

      // when using canonical tags, as we are for the blog posts, segment will
      // automatically use this as the path. We want to use the actual url so
      // we set that here. Segment can still determine the category and name so
      // we can send in undefined for those
      analytics.page(undefined, undefined, {
        path: window.location.pathname,
        url: window.location.href
      });
    })();

    /* eslint-enable */
  }

  static trackPageView(pathname) {
    if (window && window.analytics) {
      window.analytics.page(pathname);
    }
  }

  // send an event to Segment
  static trackEvent(payloadData) {
    if (window && window.analytics) {
      const eventToSend = payloadData.event;
      const payloadToSend = { event: "supercarers", ...payloadData };
      delete payloadToSend.valueSelectors;
      if (payloadData.valueSelectors) {
        Object.keys(payloadData.valueSelectors).forEach(key => {
          const inputField = document.querySelector(
            payloadData.valueSelectors[key]
          );
          payloadToSend[key] = inputField && inputField.value;
        });
      }
      window.analytics.track(eventToSend, payloadToSend);
    }
  }

  static trackIdentity(id, data) {
    if (window && window.analytics) {
      window.analytics.identify(id, data);
    }
  }
}

export default SegmentClient;
