import * as Sentry from "@sentry/browser";

class SentryClient {
  static init() {
    Sentry.init({
      dsn: `https://80589cd4c059490ca66b939be6eac54f@sentry.io/${
        window.SENTRY_WEBSITE_ID
      }`,
      release: `website@${process.env.WEBSITE_VERSION}`
    });
  }

  static captureEvent(eventInfo) {
    if (Sentry) {
      Sentry.captureEvent(eventInfo);
    }
  }
}

export default SentryClient;
