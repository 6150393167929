/* Object of all events on site -
Object key is dom element handler,
array contains event title, event category, event action and event label respectively
*/
const segmentEvents = {
  ".trustpilot-link": {
    event: "trustpilot link",
    eventCategory: "pre-user activation",
    eventAction: "trustpilot link",
    eventLabel: "click"
  },
  "#zsiq_float": {
    event: "zoho conversation start",
    eventCategory: "pre-user activation",
    eventAction: "zoho conversation start",
    eventLabel: "click"
  },
  ".becomeSuperCarer": {
    event: "become a super carer button",
    eventCategory: "pre-user activation",
    eventAction: "become a super carer button",
    eventLabel: "click"
  },
  ".findYourSuperCarer": {
    event: "find your super carer button",
    eventCategory: "pre-user activation",
    eventAction: "find your super carer button",
    eventLabel: "click"
  },
  "#discover-carers-go": {
    event: 'discover supercarers go "services"',
    eventCategory: "pre-user activation",
    eventAction: 'discover supercarers go "services"',
    eventLabel: "click"
  },
  ".waitrose-read-article, .index-read-article,  .workFamily-read-article": {
    event: "recommended by links",
    eventCategory: "pre-user activation",
    eventAction: "recommended by links",
    eventLabel: "click"
  },
  ".footer-facebook, .footer-twitter,  .footer-vimeo": {
    event: "social link click",
    eventCategory: "pre-user activation",
    eventAction: "social link click",
    eventLabel: "click"
  },
  ".types-care": {
    event: 'type of care click "services"',
    eventCategory: "pre-user activation",
    eventAction: 'type of care click "services"',
    eventLabel: "click"
  },
  ".specialist-care": {
    event: 'specialist care click "services"',
    eventCategory: "pre-user activation",
    eventAction: 'specialist care click "services"',
    eventLabel: "click"
  },
  ".subscribeMailchimp": {
    event: 'newsletter lead acquisition "footer"',
    eventCategory: "lead activation",
    eventAction: 'newsletter lead acquisition "footer"',
    eventLabel: "click",
    lead_form: "subscribe to newsletter",
    valueSelectors: {
      email: "#mce-EMAIL"
    }
  },
  ".elderlyGuidePopup": {
    event: "elderly guide lead acquisition popup",
    eventCategory: "lead acquisition",
    eventAction: "elderly guide lead acquisition popup",
    eventLabel: "click"
  },
  ".helpContact": {
    event: 'contacted via "Looking for help"',
    eventCategory: "pre-user activation",
    eventAction: 'contacted via "Looking for help"',
    eventLabel: "click"
  },
  ".involvedContact": {
    event: 'contacted via "Getting involved"',
    eventCategory: "pre-user activation",
    eventAction: 'contacted via "Getting involved"',
    eventLabel: "click"
  },
  ".workingContact": {
    event: 'contacted via "Working together"',
    eventCategory: "pre-user activation",
    eventAction: 'contacted via "Working together"',
    eventLabel: "click"
  },
  ".InfinityNumber": {
    event: "call us click",
    eventCategory: "pre-user activation",
    eventAction: "call us click",
    eventLabel: "click"
  },
  ".postcodeSearchInputHomePage": {
    event: "sign up - homepage postcode search",
    eventCategory: "pre-user activation",
    eventAction: "sign up - homepage postcode search",
    eventLabel: "submit",
    valueSelectors: {
      postcode: ".postcodeValueHomePage"
    }
  },
  "#cs-elderly-care-guide": {
    event: "vitality care guide click",
    eventCategory: "pre-user activation",
    eventAction: "vitality care guide click",
    eventLabel: "click"
  },
  "#cs-dementia-care-guide": {
    event: "vitality dementia guide click",
    eventCategory: "pre-user activation",
    eventAction: "vitality dementia guide click",
    eventLabel: "click"
  },
  "#cs-funding-care-guide": {
    event: "vitality funding guide click",
    eventCategory: "pre-user activation",
    eventAction: "vitality funding guide click",
    eventLabel: "click"
  }
};

export default segmentEvents;
