import { captureUtmTags } from "global/analytics";
import segmentEvents from "data/segmentEvents";

import SegmentClient from "clients/SegmentClient";
import SentryClient from "clients/SentryClient";

if (window.ENABLE_UTM_TRACKING) {
  captureUtmTags(document.location.href);
}

// there is a different id for staging and production, set in the config in infrastructure
if (window.SEGMENT_WEBSITE_ID) {
  SegmentClient.init();

  // Segment track events
  let eventType = "click";
  if ("ontouchstart" in window) eventType = "touchstart";

  for (let i = 0; i < Object.keys(segmentEvents).length; i += 1) {
    const selector = document.querySelectorAll(Object.keys(segmentEvents)[i]);
    if (selector !== 0 || selector) {
      for (let j = 0; j < selector.length; j += 1) {
        selector[j].addEventListener(eventType, () => {
          SegmentClient.trackEvent(Object.values(segmentEvents)[i]);
        });
      }
    }
  }
}

if (window.SENTRY_WEBSITE_ID) {
  SentryClient.init();
}
