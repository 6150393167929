export const UTM_TAGS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content"
];

export const GOOGLE_ANALYTICS_COOKIE = "_ga";

export const GCLID_COOKIE = "gclid";

export const MAX_CARER_SEARCH_DISTANCE = 800;

export const SC_POSTCODE = "EC1V 1JN";
