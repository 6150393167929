import React from "react";
import url from "url";
import Cookies from "cookies-js";
import storage from "local-storage-fallback";
import { UTM_TAGS } from "global/constants";

const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60;

// Capture UTM tags and save as a cookie
export const captureUtmTags = href => {
  const query = url.parse(href, true).query;

  UTM_TAGS.forEach(tag => {
    const value = query[tag];
    if (value) {
      Cookies.set(tag, value, {
        domain: ".supercarers.com",
        expires: THIRTY_DAYS_IN_SECONDS
      });
      storage.setItem(tag, value);
    }
  });
};

export const phoneLink = (number, style) => {
  const phoneNumber = number || "020 8629 1030";
  return (
    <a style={style} href={`tel:${phoneNumber}`}>
      {phoneNumber}
    </a>
  );
};

// export const getPhoneLink = style => {
//   const infinityNumber = Cookies.get("InfinityNumber");
//   return phoneLink(infinityNumber, style);
// };
